import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/app/middleware/redirect.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-create-product": () => import("/app/middleware/checkCreateProduct.ts"),
  "check-customization-settings": () => import("/app/middleware/checkCustomizationSettings.ts"),
  "check-edit-banner": () => import("/app/middleware/checkEditBanner.ts"),
  "check-edit-coin": () => import("/app/middleware/checkEditCoin.ts"),
  "check-edit-point-of-sales": () => import("/app/middleware/checkEditPointOfSales.ts"),
  "check-edit-product-ts": () => import("/app/middleware/checkEditProduct.ts.ts"),
  "check-notifications-settings": () => import("/app/middleware/checkNotificationsSettings.ts"),
  "check-order-owner": () => import("/app/middleware/checkOrderOwner.ts"),
  "check-shipment-setting": () => import("/app/middleware/checkShipmentSetting.ts"),
  "sanctum:auth": () => import("/app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/app/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}